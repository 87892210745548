.sales-funnel-manual-messages {
    h1 {
        font-size: 2.5rem;
    }

    .numbers-controller {
        .manual-messages-number {
            height: 100%;
            border: 1px solid #8081838c;
            border-radius: 11px;
            display: flex;
            align-items: center;
            &:focus, &:hover {
                border: 1px solid #8081838c;
                box-shadow: none;
            }
            padding: 0 2rem;
            .customer-number-item {
                align-items: center;
                border: 1px solid rgba(48, 55, 88, 0.651);
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 11px;

                .customer-number {
                    padding-left: 0.75rem;
                }
                
                .remove-number-btn {
                    border: none;
                    font-size: 1.25rem;
                    padding: 0 0.75rem;
                    margin: auto 0.3rem;
                    height: fit-content;
                }
            }
        }
    
        .manual-messages-add-number {
            width: 100%;
            text-align: center;
            color: #1890ff;
            border: 1px solid #1890ff;
            border-radius: 11px;
        }
    }

    .manual-message-card {
        margin-top: 2rem;

        .manual-messages-template-dropdown {
            width: 100%;
            border-radius: 11px 11px 0 0;

            .messages-template-dropdown {
                border-radius: 11px 11px 0 0;
                
                .ant-select-selector {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    border-radius: 11px 11px 0 0;
                }
            }
        }

        textarea {
            padding: 2rem 1.75rem;
        }
    }

    .user-name-continer {
        margin-top: 3rem;
        align-items: center;

        .ant-col {
            .ant-form-item {
                margin-bottom: 0;
            }
        }

        h5 {
            margin: auto;
            font-size: 1.5rem;
        }
    }
}

.manual-message-list-container {
    margin-top: 10rem;
}