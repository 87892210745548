
.row {
    max-width: $grid-width;
    margin: 0 auto;
    &:not(:last-child) {
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;
   
    [class^="col-"]{
             float: left;
      &:not(:last-child){
            margin-right: $gutter-horizontal;
       }
    }
    .col-1-of-2 {
        width: calc((100% - #{$gutter-horizontal}) / 2);
        
    }

    .col-1-of-3 {
        width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
    }

    .col-2-of-3 {
        width: calc(2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal});
    }

    .col-1-of-4 {
        width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
    }

    .col-2-of-4 {
        width: calc(2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal});
    }

    .col-3-of-4 {
        width: calc(3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal});
    }
 
}