@mixin clearfix {
    &::after{
         content:'';
         display: table;
         clear: both;
    }
}

@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
  }