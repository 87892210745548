

.loading-full_page {
    width: 100%;
    height: 100%;
    margin-top: 30rem;
    position: fixed;
    justify-content: center;
    display: flex;
    left: 0;
    background: #fff;
    z-index: 99;
}
.loading-full_page:after {
  // content: "";
  // width: 50px;
  // height: 50px;
  // position: absolute;
  // color: #0AD560;
  // top: -30px;
  // right: 0;
  // left: 0;
  // bottom: 0;
  // margin: auto;
  // border: 6px solid #0AD560;
  // border-top: 6px dotted #0AD560;
  // border-bottom: 6px dotted #0AD560;
  // border-radius: 50%;
  // animation: loading 2s infinite;
}
.loading-full_page:before {
  // font-family: 'ProximaNova';
  // font-size: 20px;
  // letter-spacing: 1px;
  // color: white;
  // color: #000;
  // content: "Please wait while we get your data";
  // position: absolute;
  // top: 57%;
  // text-align: center;
  // right: 0;
  // left: 0;
  // margin: auto;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(360deg);
  }
}