.funnel-timeslots-container {
    .day-selector-btn {
        border: none;

        &:focus {
            color: #54BA58;
        }

        &:hover {
            color: #54BA58;
        }
    }

    .time-slot-item {
        padding: 0 1rem;
        border: 1px solid #707070;
        border-radius: 1.25rem;
        margin: 1rem auto;
        display: block;
        width: 80%;

        &:focus {
            color: #54BA58;
            border: 1px solid #54BA58;

        }

        &:hover {
            color: #54BA58;
            border: 1px solid #54BA58;
        }
    }
}