.blogs-wrapper{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 2rem;
}


.standard-input {
    position: relative;
    width: 23%;

    input {
      width: 100%; 
      color: black;
      height: 4rem;
      border: none;
      border-bottom: 1px solid gray;
      font-size: 20px;
      transition: .1s cubic-bezier(.65, .05, .36, 1);
      outline: none;
      box-sizing: border-box;
      background-color: transparent;

      &::placeholder {
        text-align: left; 
      }

      &:hover {
        border-bottom: 2px solid darken($color: gray, $amount: 0.2);
      }

      &:focus {
        ~ .underline {
          transform: scaleX(1);
        }

        ~ label {
          color: rgb(0, 211, 90) !important;
        }
      }

      &:not(:placeholder-shown) ~ label,
      &:focus ~ label {
        top: 0;
        left: 0;
        font-size: 1.5rem;
      }
    }

    label {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: black;
      transition: .2s cubic-bezier(.65, .05, .36, 1);
      font-size: 1.5rem;
      pointer-events: none;
      padding: 0 5px;
    }

    .underline {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      transform: scaleX(0);
      transition: transform 0.2s cubic-bezier(.65, .05, .36, 1);
      border-bottom: 2px solid rgb(0, 211, 90);
    }
  }


.dropdown-menu {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  
    .dropdown-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      cursor: pointer;
      background-color: #fefefe;
      border-bottom: 1px solid #ddd;
      transition: background-color 0.3s ease-in-out;
  
      span {
        font-size: 16px;
      }
    }
  
    .dropdown-content {
      max-height: 0;
      overflow: hidden;
      padding: 0 10px;
      transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
      background-color: #fefefe;
  
      form {
        display: flex;
        flex-direction: column;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  
        &.open {
          opacity: 1;
          transform: translateY(0);
        }
  
        .row {
          display: flex;
          justify-content: space-between;
          margin: 30px 0;

          .blog-input-cont {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0rem;
            width: 20%;

            label {
              font-size: 2rem;
              font-weight: 500;
            }

            .blog-input {
              border: none;
              border-bottom: 2px solid #5AD45C;
            }
          }
        }

        .upload-image-container {
          display: flex;
          align-items: flex-start;
          gap: 3rem;

          .blog-image {
            height: 200px;
            width: auto;
            border: 1px solid #000;
          }
        }
  
        .upload-button {
          align-self: self-start;
          margin-bottom: 30px;
        }
  
        .message-container {
          height: 20px; 
        }

        .blog-text-editor {

          .ql-editor {
            height: 300px;
          }
        }
  
        .plus-icon {
          cursor: pointer;
          margin-top: 4rem;
          transition: transform 0.3s ease-in-out;
  
          svg {
            width: 24px;
            height: 24px;
            color: rgb(0, 211, 90);
          }
        }
      }
    }
  
    &.open {
      .dropdown-content {
        max-height: 1000px;
        padding: 20px 30px;
      }
    }
}  


.table-container {

  .blog-search-input {
    margin-bottom: 2.5rem;
    max-width: 300px;
    border-bottom: 2px solid #000;

    .ant-input {
      border: none;
      background-color: transparent;

      &:focus {
        box-shadow: none;
      }
    }

    .ant-input-group-addon {
      background-color: transparent;

      .ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
        cursor: pointer;
        background-color: transparent;
        color: #000;
        border: none;
      }
    }
  }

    .ant-pagination {
        display: flex;
        justify-content: flex-start;
    }

    .ant-table {
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
        border: none;

        .ant-table-content {
            border: none;
        }

        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
            text-align: left !important;
            border-bottom: 1px solid #ddd;
        }

        .ant-table-tbody > tr > td {
            border-right: none;
        }

        .ant-table-thead > tr > th {
            background-color: white;
        }

        .custom-row {
            border-bottom: 1px solid #ddd;
        }

        .ant-btn {
            margin: 0 5px;
        }
    }
    .title-link {
        color: rgb(34, 134, 255);
        font-size: 14px;
        line-height: 25px;
        word-spacing: 5px;
        padding-right: 1rem;
        text-decoration: none;
        transition: color 0.3s ease, text-decoration 0.3s ease;
      }
      
      .title-link:hover {
        color: rgb(24, 24, 199);
        text-decoration: underline;
      }

    .ant-table-tbody > tr > td .edit-btn {
        color: blue;
    }

    .ant-table-tbody > tr > td .delete-btn {
        color: red;
    }
}

.booking-delete-modal .ant-modal-footer {
  display: none;
}

.booking-delete-modal .ant-modal-content {
  border-radius: 10px;
  border: 1px solid #EB1212;
}

.booking-delete-modal .delete-modal-text {
  text-align: center;
}

.booking-delete-modal .delete-modal-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.booking-delete-modal .delete-modal-btns .cancel-button {
  border: 1px solid grey;
  border-radius: 8px;
  color: grey;
  width: 150px;
}

.booking-delete-modal .delete-modal-btns .delete-button {
  border: none;
  border-radius: 8px;
  background-color: #EB1212;
  color: #FFFFFF;
  width: 150px;
}