.all-query-table {
    .all-queries-item {
        .remarks-view-more {
            display: block;
            margin: 0.5rem auto;
            padding: 0.25rem 1rem;
            border: none;
            border-radius: 6px;
            color: #1890ff;
            background: #e3e4e5bf;
            border-color: #1890ff;
            cursor: pointer;
        }
        .ant-table-row-expand-icon-cell {
            // width: 45px;
            // height: 45px;
            // display: block;
            // padding: 1rem !important;
            // border-radius: 100%;
            margin: 0.5rem auto;

            .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed,
            .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
                display: block;
                float: unset;
                margin: auto;
                background: transparent;
                border-radius: 100%;
                color: #f0f0f0;
            }
        }

        &.follow-up-lead {
            .ant-table-row-expand-icon-cell {
                background-color: #183E80;
            }
        }

        &.closed-lead {
            .ant-table-row-expand-icon-cell {
                background-color: #FF0000;
            }
        }

        &.new-lead {
            .ant-table-row-expand-icon-cell {
                background-color: #04CF5A;
            }
        }
    }
}

.query-details {
    .query-label {
        display: block;
        text-align: left;
        width: 100%;
        padding: 0.25rem;
        font-weight: 900;
        font-family: "Raleway", sans-serif;
    }

    .query-content {
        text-align: left;
        margin: 0.5rem 0 2rem 0;
        padding: 0.75rem 1rem;
        border-radius: 8px;
        background-color: white;
        color: black;
    }
}

.query-reminder-container {
    position: fixed;
    z-index: 2222;
    top: 8rem;
    right: -60rem;
    background: white;
    width: fit-content;
    max-width: 50rem;
    border-radius: 2rem;
    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.414);
    transition: right 500ms ease-in;

    h1 {
        color: #0091FF;

        span {
            color: black;
            margin-right: 1rem;
        }
    }

    button {
        display: block;
        margin: auto;
        color: white;
        background: #0091FF;
        border: none;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        cursor: pointer;
    }

    &.active {
        right: 2rem;
        transition: right 500ms ease-in;
    }
}

.colors-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;

    .colors-container {
        span {
            padding: 0rem 0.75rem;
            margin: 0 0.5rem 0 1.5rem;
            border-radius: 100%;
        }

        &.red {
            span {
                background-color: red;
            }
        }

        &.green {
            span {
                background-color: #18B81D;
            }
        }

        &.blue {
            span {
                background-color: #183E80;
            }
        }
    }
}

.m-bottom-large {
    margin-bottom: 20rem !important;
}