.funnel-mockbidding-container {
    .timeslot-check-button {
        border: none;
        background: transparent;
        text-decoration: underline;
        color: #1492E6;
        cursor: pointer;
    }
}

.timeslots-modal-container {
    .timeslots-modal-btn {
        display: block;
        margin: 1rem auto;
        padding: 1rem 3rem;
        background: #54BA58;
        color: white;
        border: none;
        height: fit-content;
        border-radius: 5px;

        &:focus, &:hover {
            display: block;
            margin: 1rem auto;
            padding: 1rem 3rem;
            background: #54BA58;
            color: white;
            border: none;
            height: fit-content;
            border-radius: 5px;
        }
    }
}

.row-disp-none {
    display: none !important;
}