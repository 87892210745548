
// Colors
$color-primary-dark-blue: #254ab4;
$color-primary-blue: #1f73d7;
$color-green:#3bb83b;
$color-red:#d40101;
$color-primary-light: #7ed56f;  
$color-primary-dark: #28b485;
$color-dark-grey: #777;
$color-white: #fff;
$color-black: #000;
$color-grey-light-1: #f7f7f7;

$color-primary: #13196D;


// GRID
$grid-width:114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 6rem;

//FONT
$default-font-size: 1.6rem;