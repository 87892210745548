.image-gallery-div {
    .img-count-container {
        filter: brightness(70%);

        .image-count-span {
            position: absolute;
            top: 50%;
            z-index: 1;
            color: #fff;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            font-size: 2rem;
            text-align: center;
        }
    }
}