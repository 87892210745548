.u-center-text {
  text-align: center;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-margin-top-big {
  margin-top: 8rem;
}
.u-margin-top-medium {
  margin-top: 4rem !important;
}
.u-margin-top-mini {
  margin-top: 0.7rem;
}
.u-margin-top-small {
  margin-top: 1.5rem;
}

.u-margin-bottom-big {
  margin-bottom: 8rem;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem;
}
.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}
.u-margin-left-small {
  margin-left: 1.5rem;
}
.u-align-right {
  display: flex;
  justify-content: flex-end;
}
.u-align-center {
  display: flex;
  justify-content: center;
}
.card_class {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(60, 49, 49, 0.25);
  border-radius: 10px;
}
.v_align_outer {
  display: table;
  position: absolute;
  top: 5rem;
  left: 0;
  height: 100%;
  width: 100%;
}
.v_align_inner {
  display: table-cell;
  vertical-align: middle;
}
.v_align_relative {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.random {
  width: 90%;
  height: 40rem;
  margin: 0 auto;
}
.flex-parent {
  display: flex;
  flex-direction: row;
}

.flex-child-1 {
  flex: 1 1 45%;
}
.brdr_right {
  border-right: 2px solid #c4c4c4;
}
.padding_1 {
  padding: 1rem;
}
.blue_text {
  color: $color-primary-dark-blue;
}
.red_color {
  color: $color-red;
}
.height_100 {
  height: 100% !important;
  padding: 30px;

  @media (min-width: 1600px) {
    padding: 60px;
  }
}
.hide {
  visibility: hidden;
}
.flex_parent {
  display: flex;
  flex-wrap: wrap;
}

.flex_parent_child {
  flex: 1 1 45%;
}
.text-center {
  text-align: center;
}
.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.d-none {
  display: none !important;
}
.blue_text {
  color: $color-primary-blue !important;
}
.flex_child_1 {
  flex: 1 1 45%;
}

.flex-parent-new {
  display: flex;
  flex-wrap: wrap;
}

.flex-child-1-new {
  flex: 1 1 45%;
  margin: 0% 2%;
}
.u-display-flex {
  display: flex;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.image--cover {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 20px;

  object-fit: cover;
  object-position: center right;
}

.u-v-center {
  display: flex;
  align-items: center;
}

.center {
  align-self: center !important;
}

.flex-child-3 {
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.img-cover {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.flex-child-3 .ant-image {
  width: 15rem !important;
  height: 15rem !important;
}

cofs {
  background-color: rgb(184, 183, 183);
}
