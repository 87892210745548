.ant-form-item-explain, .ant-form-item-extra {
    display: contents !important;
}
.higlighted_row {
    background-color: #96B1CF !important;
}
.green_row {
    background-color: #D2F3D3 !important;
}
.red_row {
    background-color: #FADBDB !important;
}
.u-margin-1 {
    margin: .5rem !important;
    display: block;
}
.flex-child-1-new > .ant-form-item {
    display: block;
}
.flex-parent-new {
    word-break: break-all;
}

.informnation-wrapper-modal {
    display: flex;
    padding: 1rem;
}

.informnation-child-1 {
    display: flex;
    flex-direction: column;
    padding: .2rem;
    flex: 1 1 50%;
    word-break: break-all;
}
.information-heading {
    margin-bottom: .8rem;
    font-size: 1.3rem;
    font-weight: bold;
}

.information-content {
    font-size: 1.2rem;
    word-break: break-all;
}

.heading-new {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: .8rem;
}

.video-div {
    background-color: #F2F2F2;
    padding: .5rem 1rem;
    font-size: 1.2rem;
}

.video-reports-wrapper {
    padding: 1rem;
}

#light-gallery-first-image {
    height: 15rem;
    /* width: 15rem !important; */
}

.video-reports-child-1 {
    flex: 1 1 45%;
    margin: 1rem 1rem 1rem 0rem;
}
.video-reports-wrapper {
    display: flex;
}
.padding-1rem {
    padding: 1rem;
}
.video-div {
    font-size: 1rem;
}