.solution-user-details {
    .body-part-cards{
        border-radius: 11px;
        background: #f5f5f5;

        .ant-card-body {
            padding: 1rem;

            .ant-row {
                justify-content: center;
                align-items: center;
                .body-part-col {
                    span {
                        color: rgb(139, 139, 139);
                        font-size: 1.2rem;
                    }
                    color: black;
                    font-weight: 400;
                }

                .body-col-separator {
                    font-size: 2rem;
                    font-weight: 900;
                }
            }
        }
    }
}

.selected-hospitals-list {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    margin: 2rem 1rem;

    span {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;
        background: #F8F8F8 0% 0% no-repeat padding-box;
        border-radius: 12px;
        padding: 0.75rem 1.5rem;

        button {
            display: block;
            height: 100%;
            border: none;
            background: transparent;
            color: black;
            margin-left: 0.75rem;
            font-weight: 900;
            cursor: pointer;
        }
    }
}

.booking-add-ons {
    display: flex !important;
    flex-direction: column;

    label {
        display: block;
        margin: auto;
    }
}
.submit-updates {
    margin: 3rem 0;
    .ant-btn {
        display: block;
        margin: auto;
        padding: 1rem 1.5rem;
        height: 100%;
        font-size: 1.5rem;
        border-radius: 13px;
        border: none;
        color: white;
        background-color: #2BBC52;
    }
}

.patient-details-box {
    .patient-details-card {
        // padding: 1.5rem 0.75rem;
        .ant-card-body {
            padding: 2.5rem !important;

            .ant-form-item {
                flex-direction: column;

                .ant-form-item-label {
                    text-align: left;
                }

            }
            .label-class {
                display: block;
                margin-bottom: 1rem;
            }

            .divider-text {
                color: #1C95C2;
                font-size: 1.5rem;
            }

            // .photo-gallery-docs {
            //     // flex-direction: row;
            // }

            .otp-container {
                .btn-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .ant-btn {
                        border: none;
                        border-radius: 13px;
                        font-size: 1.5rem;
                        background-color: #2BBC52;
                        color: white;
                        padding: 1rem 1.5rem;
                        height: fit-content;
                    }
                }
            }
        }
    }
}

.booking-info-card {
    background-color: rgb(232, 234, 239);
    padding: 1rem;
    margin-top: 2rem;

    .ant-col {
        margin: 1rem 0;
        padding-left: 2rem;
        label {
            display: block;
            font-weight: 300;
            color: #2d2d2db4;
        }

        span {
            display: block;
            font-weight: 700;
        }
    }

    &.bill-amounts {
        label {
            font-weight: 500;
        }
    }
}