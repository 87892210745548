#campaign-treatment-head {
    width: 321px;

    h1 {
        margin: 1.5rem 0;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 700;
        color: #3F7ACD;
        text-align: center;
        word-break: normal;
        line-break: strict;
        font-size: 2.5rem;

        &.service-heading {
            color: #1E469F;
        }

        span {
            font-family: 'Poppins', sans-serif !important;
            font-weight: 600;

            b {
                color: rgb(37, 178, 129);
                font-family: 'Poppins', sans-serif !important;
                font-weight: 600; 
            }

            span {
                font-family: 'Poppins', sans-serif !important;
                color: #3F7ACD;
                text-align: left;
                font-weight: 500;
            }
        }

    }
}