.add-user-section {
    .row {
        display: flex;
        .col-6 {
            width: 50%;
            padding: 0.5rem;
        }
        .col-12 {
            width: 100%;
            padding: 0.5rem;
        }

        & [class^="col-"]:not(:last-child) {
            margin-right: 0;
        }
    }
    .details-box {
        max-height: 700px;
        overflow: scroll;
    }
    .user-details-box {
        background-color: #F0F2F5;
        padding: 1.25rem;
        margin-top: 1rem;

    }
    .policy-details-box {
        .form-input {
            border: 1px solid #BEBDBD;
        }

        .booking-dropdown__control {
            border: 1px solid #BEBDBD;
        }

        .dob-date-picker {
            border: 1px solid #BEBDBD;
        }
    }

    label {
        margin-top: 1rem;
        display: block;
        color: #000000;
        font-weight: 900;
        margin-bottom: 0.25rem;
        font-size: 1.5rem;
    }

    .form-input {
        width: 100%;
        font-size: 1rem;
        padding: 0.5rem 0.75rem;
        border: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000008;
        border-radius: 3px;
    }

    .booking-dropdown__control {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000008;
        border-radius: 3px;
        border: none;
    }

    .dob-date-picker {
        width: 100%;
        font-size: 1rem;
        padding: 0.75rem;
        border: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000008;
        border-radius: 3px;
    }

    .upload-docs-container {
        margin-top: 1rem;
        margin-bottom: 0;
        .upload-docs-link {
            color: #2D92FF;
            display: inline;
        }

        i {
            margin-left: 0.5rem;
            color: #2D92FF;
        }

        input {
            margin-top: 1rem;
            display: block;
        }
    }

    .submit-btn button {
        display: block;
        width: 15%;
        border: none;
        padding: 0.75rem 2rem;
        background: #5AD45C 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000008;
        border-radius: 32px;
        margin: 2rem auto;
        color: white;
        font-size: 1.5rem;
        cursor: pointer;
    }
}

.booking-info.view-details-btn {
    border: none;
    border-radius: 2rem;
    color: white;
    background-color: #5AD45C;
    padding: 0.5rem 1rem;
}

.edit-session-button {
    border: none;
    background-color: transparent;
    color: #1f73d7;
    padding: 0.5rem 1rem; 
}

.session-label, .policy-label {
    margin: 1rem auto 0.5rem auto;
    display: block;
}

.policy-label {
    color: #000000;
}

.update-rent-details-btn {
    border: none;
    border-radius: 2rem;
    color: white;
    background-color: #5AD45C;
    padding: 0.75rem 1.5rem;
    height: 100%;
    margin-top: 2.6rem;
}


.booking-info.view-details-btn {
    border: none;
    border-radius: 2rem;
    color: white;
    background-color: #5AD45C;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.edit-session-button {
    border: none;
    background-color: transparent;
    color: #1f73d7;
    padding: 0.5rem 1rem; 
    cursor: pointer;
}

.session-label, .policy-label {
    margin: 1rem auto 0.5rem auto;
    font-weight: 900;
    display: block;
}

.policy-label {
    color: #000000;
}

.insurance-booking-session {
    margin-bottom: 1rem !important;

    .ant-input {
        padding: 0.75rem;
    }

    .ant-form-item {
        margin-bottom: 0rem;
    }
}

.insurance-booking-status {
    cursor: pointer;
}