.country-regin-wrapper {
    width: 100%;
    margin-top: .3rem;
    width: 100%;
  }
  
  .country {
    width: 100%;
    padding: 8px 16px;
  }

  .country, .regin {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #EEEEEE;
    padding-left: 1rem;
    outline: none;
    transition: 0.3s;
    font-family: Poppins;
    font-style: normal;
    box-sizing: border-box;
    font-size: 1.5rem;
   
  }
//   .country::placeholder {
//     color: blue !important;
//   }

select {
    color: inherit !important;
  }
  option:not(:first-of-type) {
    color: black;
  }
  .country:focus{
      border-color: #70b3df;
  }