.section-about{
    background-color:  $color-grey-light-1;
    padding: 25rem 0;
    margin-top: -20vh;
} 
#dashboard_content {
    min-height: 100vh;
    height: 100%;
}
.site-page-header {
    border: 1px solid rgb(235, 237, 240);
  }

  .table-wrapper {
      display: flex;
      flex-direction: column;
      padding: 3px;
  }
  .table-heading {
        display: flexbox;
        flex-direction: row;
        justify-content: space-between;
        background-color: #EBEBEB;
        padding: 5px;
  }
  .table-body {
        padding: 3px;
        text-align: center;
  }
  .table-body-row {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
  }
  .table-body-text {
      font-size: 1.2rem;
  }
  .heading-4 {
        flex: 1 1 40%;
  }
  .heading-3 {
    flex: 1 1 33%;
  }
  .heading-2 {
    flex: 1 1 25%;
    justify-content: center;
    align-items: center;
  }

  .table-body-4 {
    flex: 1 1 40%;
    justify-content: center;
    align-items: center;
}
.table-body-3 {
    flex: 1 1 33%;
    justify-content: center;
    align-items: center;
}
.table-body-2 {
flex: 1 1 25%;
}

  .table-heading {
      font-weight: 400;
      font-size: 1.8rem;
      text-transform: capitalize;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .heading-half {
      flex: 1 1 45%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .table-body-text {
      font-size: 1.4rem;
  }
  .wrapper-card-text {
      font-size: 1.3rem;
    //   font-weight: bold;
  }
  .green-box {
      height: 15px;
      width: 15px;
      border-radius: 2px;
      background-color: #58D05C;
      display: block;
      margin-right: 5px;
  }
  .orange-box {
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 2px;
    background-color: #F77C3E;
    margin-right: 5px;
}
.wrapper-card {
    display: flex;
    padding: 4px;
    background-color: white;
    box-shadow: 2px 2px #ebe8e8;
    border-radius: 3px;
    text-align: center;
    margin: 1% 2%;
}

.wrapper-card-4 {
    flex:  1 1 39%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrapper-card-3 {
    flex:  1 1 29%;
}

.cover-img-small {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    position: relative;
    object-position: center;
}

.profile-image {
    width: 10rem;
    height: 10rem;
    margin: 0rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center right;
    position: absolute;
    right: 35%;
    top: 28%;
}
.profile-wrapper {
    height: 15rem;
}
.bold-heading {
    margin-bottom: 0rem !important;
    font-size: 2rem;
    font-weight: bold;
}
.introdiuction-wrapper {
    margin-left: 2rem;
    padding: 1rem;
}
.wrapper {
    padding: 1rem;
}
.speciality-item {
    background-color: #FAFAFA;
    padding: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1rem 0rem;
}
.specilaity-wrapper {
    height: 30rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: transparent;
}

.overflow-scroll {
    height: 30rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: transparent;
}

.flex-parent-1 {
    display: flex;
    justify-content: space-between;
}
.flex-child-1 {
    flex: 1 1 45%;
}
.ant-upload-select-picture-card {
    width: 80%  !important;
    height: 20rem  !important;
}

.ant-upload > img {
    height: inherit !important;
}

