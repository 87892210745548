.nav {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    padding-right: 2rem;
    border-bottom: 2px #0000000d solid;
}

.nav > p {
    margin: 2rem;
}

.display{
    display: grid;
    grid-template-columns: 35% 65% ;
}
.view{
    display: grid;
    grid-template-columns: 15% 85%;
}

ul{
    list-style-type: none;
}

ul p{
    white-space: nowrap;
    padding: 1rem 2rem;
    font-size: 1.3rem;
    color: #848484;
}

li:hover{
    background-color: #e6f7fe;
    cursor: pointer;
}

.panel_opt{
    margin-left: -1.5rem;
    padding-top: 2rem;
}

.upload{
    padding: 1rem;
    background-color: #fafafa;
    border-left: #00000021 1px solid;
    border-right: #00000021 1px solid;
}

.upload p{
    padding-top: 2rem;
}

.submit{
    background: #258ffb;
    border: none;
    border-radius: 1.2rem;
    padding: 0.5rem 1.2rem;
    color: white;
    font-size: 1.1rem;
    margin: 2rem;
    outline: none;
}

.submit:hover{
    background-color: #52a7fc;
    cursor: pointer;
}

.table{
    padding: 1rem;
    padding-right: 3rem;
    background-color: white;
}
/* 
@media screen and (min-width: 951px) {
    .display{
        grid-template-columns: 15% 15% 70%;
    }
}

@media screen and (max-width: 951px){
    .display{
        grid-template-columns: 50% 50%;
    }
    .table {
        grid-column-start: span 2;
        padding-right: 1rem;
    }
    .upload{
        margin-right: 1rem;
        border-right: none;
    }
} */