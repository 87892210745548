@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }
    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }
    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInButton {
    0% {
        opacity: 0;
        transform:translateY(10rem);
    }
    100% {
        opacity: 1;
        transform:translateY(0px);
    }
}

@keyframes notif_drawer {
    0% {
        opacity: 0;
        transform: translateY(0rem);
    }
    50% {
        opacity: 0.5;
        transform: translateY(5rem);
    }
    100% {
        opacity: 1;
        transform: translateY(10rem);
    }
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 330ms;
  
    @keyframes fadeInOpacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
  }