#topbar {
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    font-size: 14px;
  }
  
  #topbar .contact-info a {
    line-height: 1;
    color: #444444;
    transition: 0.3s;
  }
  
  #topbar .contact-info a:hover {
    color: #01b1d7;
  }
  
  #topbar .contact-info i {
    color: #01b1d7;
    padding: 4px;
  }
  
  #topbar .contact-info .phone-icon {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #e9e9e9;
  }
  
  #topbar .social-links a {
    color: #62787d;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
    border-left: 1px solid #e9e9e9;
  }
  
  #topbar .social-links a:hover {
    color: #01b1d7;
  }
  
  #topbar .social-links a:first-child {
    border-left: 0;
  }
  
  #header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: #fff;
    box-shadow: 0 1px 8px 0 rgba(0,0,0,.25);
  }
  
  #header.header-scrolled {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  #header .logo h1 a, #header .logo h1 a:hover {
    color: #354144;
    text-decoration: none;
  }
  
  #header .logo h1 a span, #header .logo h1 a:hover span {
    color: #01b1d7;
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    height: 55px;
  }
  
  .nav-menu, .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
  }
  
  .nav-menu a {
    display: block;
    position: relative;
    color: #354144;
    padding: 10px 15px;
    transition: 0.3s;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  
  .nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
    color: #254AB2;
    text-decoration: none;
  }
  
  .nav-menu .drop-down ul {
    display: block;
    position: absolute;
    left: 0;
    top: calc(100% - 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: ease all 0.3s;
  }
  
  .nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
  }
  
  .nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #354144;
  }
  
  .nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
    color: #01b1d7;
  }
  
  .nav-menu .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
  }
  
  .nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
  }
  
  .nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
  }
  
  .nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
  }
  
  .nav-menu .drop-down .drop-down > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
  }

@media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
      left: -90%;
    }
    .nav-menu .drop-down .drop-down:hover > ul {
      left: -100%;
    }
    .nav-menu .drop-down .drop-down > a:after {
      content: "\ea9d";
    }
  }

  .logo {
	position: relative;
    
    float: left;
}